import React, { useState, useEffect } from "react";
import "./Hero.css";
import Button from "../../../components/button/Button";

const Hero = () => {
  const [typedQuote, setTypedQuote] = useState(""); // State to hold the typed quote
  const quote = "Thee journey of a thousand miles begins with one step."; // Full quote

  useEffect(() => {
    let index = 0; // Starting index for typing

    const typingInterval = setInterval(() => {
      if (index < quote.length) {
        // Use functional update to ensure correct typing
        setTypedQuote((prev) => prev + quote.charAt(index));
        index++;
      } else {
        clearInterval(typingInterval); // Clear the interval when done typing
      }
    }, 100); // Typing speed (100ms per character)

    return () => clearInterval(typingInterval); // Cleanup interval when component unmounts
  }, [quote]);

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Hi, I'm Manuel Lipo</h1>
        <h2 className="hero-profession">Full-Stack Developer</h2>

        {/* Typing effect applied to the quote */}
        <p className="quote">{typedQuote}</p>

        <div className="hero-cta-buttons">
          <Button url={"/portfolio"} name={"My Work"} />
          <Button url={"/contact"} name={"Contact Me"} />
          <Button
            url={"/files/Manuel-Lipo-CV.pdf"}
            downloadLink={"Manuel-Lipo-CV.pdf"}
            name={"Download CV"}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
