import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Me</h1>
      <p>I am a self-taught full-stack web developer with a strong passion for technology and problem-solving. My journey into development started with a deep interest in computers, evolving from customizing visual elements and fixing hardware to mastering web technologies. Despite not completing my computer science degree, I have independently built proficiency in HTML, CSS, JavaScript, React, Ruby, Ruby on Rails, SQL, PostgreSQL, and GitHub. Alongside my technical skills, I’ve gained valuable life experience from various jobs, building adaptability, communication, and teamwork. I am eager to apply my skills to create impactful digital solutions.
      </p>
    </div>
  );
};

export default About;
